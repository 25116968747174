export const Additional = () => {
  return (
    <div className="additional">
      <p className="additional__title">Additional information</p>
      <div className="additional__wrapper additional__wrapper--mobile">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Updated</p>
            <p className="additional__description">08.08.2024</p>
          </div>

          <div className="additional__item">
            <p className="additional__text">Installed</p>
            <p className="additional__description">1347444</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Age</p>
            <p className="additional__description">18+</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Size</p>
            <p className="additional__description">3.3MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Current version</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Developer</p>
            <p className="additional__description">Secrets of Cleopatra</p>
          </div>
        </div>
      </div>
      <div className="additional__wrapper additional__wrapper--desktop">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Updated</p>
            <p className="additional__description">08.08.2024</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Size</p>
            <p className="additional__description">3.3MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Installed</p>
            <p className="additional__description">1347444</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Current version</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Age</p>
            <p className="additional__description">18+</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Developer</p>
            <p className="additional__description">Secrets of Cleopatra</p>
          </div>
        </div>
      </div>
    </div>
  );
};
